import React from 'react'

const Faqs = () => {
  return (
    <div className="faqs-wrapper py-0 py-lg-5" id="faqs">
        <div className="py-6 container ">
        <h2 className="font-bold text-center section-title">سوالات <span className="text-main">متداول</span></h2>
        <p className="text-center text-text phone-font-18 font-20 mt-3">به سوالات پرتکرار شما پاسخ داده ایم</p>

        
        <div className="py-lg-3">
        <details>
          <summary>چقدر طول میکشه که منو آماده بشه؟</summary>
          <p className="mt-4 text-text ">
            بعد از ثبت نام و پرداخت آنلاین هزینه پکیج مورد نظر بلافاصله میتونید شروع به ساخت آیتم های منو کنید. <br />
            منوی شما چند دقیقه بعد در آدرس مشخص شده در پنل فعال خواهد شد .
            </p>
        </details>  
        <details>
        <summary>آیا میشه آیتم های منو رو ویرایش کرد؟</summary>
          <p className="mt-4 text-text">
          بله. در تمامی پکیج های داینز از طریق پنل مدیریت میتونید تمام آیتم‌ها (قیمت، عنوان و ...) رو به راحتی از طریق موبایل یا لپ تاپ خود ویرایش کنید.            </p>
        </details>  
 
        <details>
        <summary>خودم میتونم یک آیتم جدید به منو اضافه کنم؟</summary>
          <p className="mt-4 text-text">
          از طریق پنل مدیریت به راحتی با چند کلیک هر تعداد آیتم جدید به منوی خود اضافه کنید.           </p>
        </details>  
        <details>
        <summary >چه جوری میتونم منو رو ببینم؟</summary>
                  <p className="mt-4 text-text">
                  بعد از تکمیل سفارش ما QR کد مخصوص منوی شما را به شما تحویل میدهیم و با یک اسکن منو قابل مشاهده است. از اونجایی که داینز یک وب اپلیکیشن می باشد همچنین میتوانید از طریق Url به منو دسترسی داشته باشید مثلا : demo.dinez.ir            </p>
        </details>  
 

        </div>

    </div>
    </div>
  )
}

export default Faqs