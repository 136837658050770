import React, { useState } from 'react';
import check from '../images/check.svg'
import x from '../images/red-x.svg'
import PrivacyPolicy from "./PrivacyPolicy";

const Prices = () => {
  const [is6month, setIs6month] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const PackagesPrice = [
    {id : 1, prices : 
      {
        price6: '1,550,000',
        price12: '2,500,000'
      }
    },
  ]

  const getPackagePrice = (id) => {
    var packagePrices = PackagesPrice.find((p) => p.id === id).prices;
    return is6month ? packagePrices.price6 : packagePrices.price12;
  }

  const handleReservation = async (packageName) => {
    if (phoneNumber) {
      try {
        const response = await fetch('https://my.dinez.ir/reservation/ ', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({'phone': phoneNumber, 'package' : packageName}),
        });
        const data = await response.json();
  
        if (data.data === 'success') {

          document.querySelector('.reserve1').value = '';
          document.querySelector('.reserve2').value = '';

          document.querySelector('.reserve-success').classList.remove('d-none');
          
          setTimeout(() => {
            document.querySelector('.reserve-success').classList.add('d-none');
          }, 8000);
  
        } else {
          console.error('Reservation failed');
        }
      } catch (error) {
        console.error('Error Reservation:', error);
      } 
    }
  };

  return (
    <>
    <PrivacyPolicy showPrivacyPolicy={showPrivacyPolicy} setShowPrivacyPolicy={setShowPrivacyPolicy} />
    <div className="prices-wrapper py-0 py-lg-5" id="prices">
      <div className="round reserve-success position-fixed   d-none  mt-3 p-3 text-center bg-success">
        رزرو انجام شد. منتظر تماس ما باشید.
      </div>
      <div className="container py-6">
        <h2 className="font-bold text-center section-title">قیمت ها را<span className="text-main">مقایسه</span> کنید</h2>
        <p className="text-center text-text phone-font-18 font-20 mt-3">تلاش ما ارائه  <b className="text-blue">بالاترین کیفیت</b> و معقول ترین قیمت به شماست</p>
      <div className=" text-center  my-5">
        <div className="  price-switcher d-flex p-2 cursor-pointer  rounded-pill my-0 mx-auto">
        <div className={` ${is6month ? 'bg-blue': ''} p-2 w-50 round `}  onClick={() => setIs6month(true)}>6 ماهه</div>
          <div className={` ${is6month ? '': 'bg-blue'} p-2 w-50 round `}  onClick={() => setIs6month(false)}>یکساله</div>
        </div>
      </div>
        <div className="row  justify-content-center py-0 py-lg-5" >
          <div className="col-md-12 col-lg-4 mt-lg-5 mb-5 order-2 order-lg-0 mb-lg-0 d-none d-lg-block">
            <div className="price-card p-4 d-flex justify-content-center flex-column  text-center">
            <span className="price-card-title">پکیج <span className="font-bold">استاندارد</span></span>

            <h2 className="text-info my-3 ">به زودی</h2>
            <p className="text-main font-20 w-75 mx-auto">بعد از انتشار با <b className="text-decoration-underline">15٪ تخفیف</b> خرید کنید</p>

              <div className="reserve-package bg-dark border-blue border round d-flex my-4">
              <button
                className="bg-dark text-blue btn border-blue bg-main w-25"
                onClick={() => handleReservation('استاندارد')}
              >
                رزرو کنید
              </button>
                <input
                  style={{ direction: 'rtl' }}
                  type="tel"
                  className="bg-dark border-0 p-3 text-white round reserve1 w-75"
                  placeholder="شماره تماس خود را وارد کنید"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              <div className="d-flex flex-column text-end border-top position-relative border-secondary pt-3">
              <div className="soon"></div>

                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> شروع آدرس منو با نام برند شما</span>
                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> نمایش لوگو و نام و شعار برند</span>
                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" />صفحه معرفی مجموعه</span>
                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> افزودن گزینه های انتخابی برای آیتم</span>
                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> دکمه تماس برای بیرون مجموعه</span>
                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> قابلیت تغییر وضعیت آیتم به "تمام شده"</span>
                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> قابلیت پنهان سازی آیتم</span>
                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> مشخص کردن زمان آماده سازی آیتم</span>
                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> صفحه داخلی آیتم با جزئیات بیشتر</span>

              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-4 order-1 order-lg-1  mb-5 mb-lg-0">
            <div className="price-card p-4 d-flex justify-content-center flex-column  text-center">
            <span className="price-card-title">پکیج <span className="font-bold">پایه</span></span>
            <span className="price "><span className="number">{getPackagePrice(1)}</span> تومان</span>
              <p className="text-info font-20">مناسب برای یک شروع قدرتمند</p>
              <button onClick={() => setShowPrivacyPolicy(true)} type="button" className="text-decoration-none  price-card-btn text-white mb-4">
                ثبت سریع سفارش
              </button>
              <div className="d-flex flex-column text-end border-top border-secondary pt-3">
                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> شروع آدرس منو با نام برند شما</span>
                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> نمایش لوگو و نام و شعار برند</span>
                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" />صفحه معرفی مجموعه</span>
                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> افزودن گزینه های انتخابی برای آیتم</span>
                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> دکمه تماس برای بیرون مجموعه</span>
                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> قابلیت تغییر وضعیت آیتم به "تمام شده"</span>
                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> قابلیت پنهان سازی آیتم</span>
                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> مشخص کردن زمان آماده سازی آیتم</span>
                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> صفحه داخلی آیتم با جزئیات بیشتر</span>
                <div className="text-center text-main cursor-pointer mt-4" onClick={() => {setShowDetails(!showDetails)}}>{showDetails ? 'بستن جزئیات' : 'جزئیات بیشتر'}</div>
                <div className={`${showDetails ? 'd-flex' : 'd-none'} mt-4 flex-column text-end`}>
                  <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> دسته بندی و آیتم نامحدود </span>
                  <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> پنل مدیریت منو</span>
                  <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> درج محتویات و توضیحات برای هر آیتم</span>
                  <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> یادداشت سفارش مهمان (سبد خرید)</span>
                  <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> شخصی سازی منو به رنگ سازمانی برند</span>
                  <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> آیکن های دسته بندی جذاب</span>
                  <span className="font-20 phone-font-15"><img src={x} width={50} style={{marginLeft:'-10px'}} alt="red x" />ورود منو برای راه اندازی</span>
                  <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" />پشتیبانی و آموزش رایگان</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-4 mt-lg-5 order-lg-2 d-none d-lg-block order-2">
            <div className="price-card p-4 d-flex justify-content-center flex-column  text-center">
            <span className="price-card-title">پکیج <span className="font-bold">حرفه ای</span></span>
            <h2 className="text-info my-3 ">به زودی</h2>
            <p className="text-main font-20 w-75 mx-auto">بعد از انتشار با <b className="text-decoration-underline">30٪ تخفیف</b> خرید کنید</p>
              <div className="reserve-package bg-dark border-blue border round d-flex my-4">
              <button
                className="bg-dark text-blue btn border-blue bg-main w-25"
                onClick={() => handleReservation('حرفه ای')}
              >
                رزرو کنید
              </button>
                <input
                  style={{ direction: 'rtl' }}
                  type="tel"
                  className="bg-dark border-0 p-3 text-white round reserve2 w-75"
                  placeholder="شماره تماس خود را وارد کنید"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              <div className="d-flex flex-column text-end border-top position-relative border-secondary pt-3">
              <div className="soon"></div>

                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> شروع آدرس منو با نام برند شما</span>
                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> نمایش لوگو و نام و شعار برند</span>
                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" />صفحه معرفی مجموعه</span>
                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> افزودن گزینه های انتخابی برای آیتم</span>
                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> دکمه تماس برای بیرون مجموعه</span>
                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> قابلیت تغییر وضعیت آیتم به "تمام شده"</span>
                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> قابلیت پنهان سازی آیتم</span>
                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> مشخص کردن زمان آماده سازی آیتم</span>
                <span className="font-20 phone-font-15"><img src={check} width={50} style={{marginLeft:'-10px'}} alt="check" /> صفحه داخلی آیتم با جزئیات بیشتر</span>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>

  )
}

export default Prices