import React from 'react'

const Reviews = () => {
  return (
    <div className="reviews-wrapper py-0 py-lg-5" id="reviews">
    <div className="container py-6">
        <h2 className="font-bold text-center section-title" >به ما <span className="text-main">اعتماد</span> کرده‌اند</h2>
        <p className="text-center text-text mt-3 phone-font-18 font-20">تجربه ی مدیرانی که از داینز استفاده کرده اند</p>
      <div className="row justify-content-center align-items-center ">
        <div className="col-md-12">
          <div className="review-carousel row py-lg-5 pb-0 pt-5 px-2 d-flex flex-nowrap" style={{overflowX: 'scroll'}}>

            <div className="review-wrapper col-md-6 col-lg-4 ">
              <div className="d-flex justify-content-between mb-2">
              <p className="m-0 text-main">کافه رستوران بامبو</p>
              </div>
              <div className="review-card bg-bg p-4 font-20 phone-font-18">
              با این خدمات کیفیتی تجربه کردم که واقعاً تحت تأثیر قرار گرفتم. پیشنهاد می‌کنم.
              </div>
            </div>
            <div className="review-wrapper col-md-6 col-lg-4 ">
              <div className="d-flex justify-content-between mb-2">
              <p className="m-0 text-main">فست فود دانژه</p>
              </div>
              <div className="review-card bg-bg p-4 font-20 phone-font-18">
                بهترین ویژگیشون تیم حرفه ایشونه که خیلی خوب و مسلط هستند به کارشون و پشتیبانی خیلی خوبی دارن. راضی هستیم.
              </div>
            </div>
            <div className="review-wrapper col-md-6 col-lg-4 ">
              <div className="d-flex justify-content-between mb-2">
              <p className="m-0 text-main">فست فود آقاجون</p>
              </div>
              <div className="review-card bg-bg p-4 font-20 phone-font-18">
                 اولین تجربه‌ی استفاده ی ما از منوی دیجیتال بود، برای شروع بسیار کمک کردن بهمون، به امید همکاری طولانی مدت.
              </div>
            </div>
            <div className="review-wrapper col-md-6 col-lg-4 ">
              <div className="d-flex justify-content-between mb-2">
              <p className="m-0 text-blue">به جمع همراهان داینز بپیوندید</p>
              </div>
              <div className="review-card  border border-main p-4 d-flex justify-content-center align-items-center">
                <a href="#prices" className="btn text-dark bg-main round-btn p-3">ثبت سریع سفارش </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Reviews