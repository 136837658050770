import './index';
import Navbar from './components/Navbar';
import Hero from "./components/Hero";
import Faqs from "./components/Faqs";
import Prices from './components/Prices';
import Features from "./components/Features";
import Reviews from "./components/Reviews";
import Steps from "./components/Steps";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <Features />
      <Prices />
      <Faqs />
      <Reviews />
      <Steps />
      <Footer />
    </div>
  );
}

export default App;
