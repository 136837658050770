import React from 'react'
import experts from '../images/experts.png'
import uiuix from '../images/uiuix.png'
import update from '../images/update.png'
import featuresIcon from '../images/features.png'
import loading from '../images/loading.png'
import quick from '../images/quick.png'
import panel from '../images/panel.png'
import support from '../images/support.png'

const Features = () => {
  return (
    <div className="features-wrapper py-0 py-lg-5" id="features">
      <div className="container py-6">
      <h2 className="font-bold text-center section-title">چرا داینز <span className="text-main">متمایز</span> است</h2>
      <p className="text-center text-text phone-font-18 font-20 mt-3">برخی از ویژگی‌های منوی دیجیتال داینز</p>
        <div className="row align-items-center py-5">


          <div className="col-md-6 col-lg-3 text-center mb-5">
            <div className="text-center features-box mx-5 p-3 py-4">
                <img src={experts} width={70} height={70} alt="experts" />
            </div>
                <p className="m-0 font-20 ">تیم متخصص</p>
                <p className="text-text phone-phone-font-15 mt-2 w-75 mx-auto">تیمی تشکیل شده از توسعه دهندگان کار در کنار شما   </p>
          </div>
          <div className="col-md-6 col-lg-3 text-center mb-5">
            <div className="text-center features-box mx-5 p-3 py-4">
            <img src={uiuix} width={70} height={70} alt="uiuix" />
            </div>
                <p className="m-0 font-20">طراحی متفاوت</p>
                <p className="text-text phone-font-15 mt-2 w-75 mx-auto">منو و پنل مدیریت داینز هر روز رفع اشکال خواهد شد</p>
          </div>
 
          <div className="col-md-6 col-lg-3 text-center mb-5">
            <div className="text-center features-box mx-5 p-3 py-4">
            <img src={update} width={70} height={70} alt="update" />
            </div>
            <p className="m-0 font-20 ">آپدیت روزانه منو</p>
            <p className="text-text phone-font-15 mt-2 w-75 mx-auto">داینز هیچوقت تکمیل نخواهد شد، هر روز آن را بهتر میکنیم.</p>

          </div>
 
          <div className="col-md-6 col-lg-3 text-center mb-lg-5">
            <div className="text-center features-box mx-5 p-3 py-4">
            <img src={featuresIcon} width={70} height={70} alt="features" />
            </div>
            <p className="m-0 font-20 ">امکانات زیاد</p>
            <p className="text-text phone-font-15 mt-2 w-75 mx-auto">ایده‌ی داینز ارائه ی تمام امکاناتی است که راحتی کاربر را تضمین کند</p>

          </div>
 
          <div className="col-md-6 col-lg-3 text-center mt-5">
            <div className="text-center features-box mx-5 p-3 py-4">
            <img src={support} width={70} height={70} alt="support" />
            </div>
            <p className="m-0 font-20 "> آموزش و پشتیبانی </p>
            <p className="text-text phone-font-15 mt-2 w-75 mx-auto">به صورت ۲۴ساعته پشتیبانی در خدمت شماست</p>

          </div>
 
          <div className="col-md-6 col-lg-3 text-center mt-5">
            <div className="text-center features-box mx-5 p-3 py-4">
            <img src={panel} width={70} height={70} alt="panel" />
            </div>
            <p className="m-0 font-20 ">پنل مدیریت</p>
            <p className="text-text phone-font-15 mt-2 w-75 mx-auto">با پنل مدیریت قدرتمند داینز، منوی خود را مدیریت کنید</p>

          </div>
 
          <div className="col-md-6 col-lg-3 text-center mt-5">
            <div className="text-center features-box mx-5 p-3 py-4">
            <img src={loading} width={70} height={70} alt="loading" />
            </div>
            <p className="m-0 font-20 ">سرعت بالا</p>
            <p className="text-text phone-font-15 mt-2 w-75 mx-auto">سرعت لود بالای منو باعث رضایت بیشتر مشریان شماست</p>

          </div>
 
          <div className="col-md-6 col-lg-3 text-center mt-5">
            <div className="text-center features-box mx-5 p-3 py-4">
            <img src={quick} width={70} height={70} alt="quick" />
            </div>
            <p className="m-0 font-20 ">ثبت سریع منو</p>
            <p className="text-text phone-font-15 mt-2 w-75 mx-auto">در کمتر از یک دقیقه سفارش منوی خود را ثبت کنید</p>

          </div>
 

        </div>
      </div>
    </div>
  )
}

export default Features