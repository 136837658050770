import React, { useState } from 'react';
import step1 from '../images/step1.png'
import step2 from '../images/step2.png'
import step3 from '../images/step3.png'

const Steps = () => {
  const [selectedStep, setSelectedStep] = useState(0);

  const stepData = [
    {
      imageSrc: `${step1}`,
      text: 'با کلیک بر روی دکمه ثبت سریع سفارش در قسمت پکیج ها وارد قسمت ثبت نام خواهید شد، با وارد کردن نام برند خود و شماره تماس به راحتی ثبت نام کنید',
    },
    {
      imageSrc: `${step2}`,

      text: 'بعد از ثبت نام در پنل مدیریت، پکیج مورد نظر را انتخاب و صورتحساب را به صورت آنلاین پرداخت کنید، همجنین پروفایل شخصی و مجموعه در این قسمت تکمیل میشود.',
    },
    {
      imageSrc: `${step3}`,

      text: 'بعد از پرداخت بلافاصله منوی شما فعال خواهد شد و میتوانید ساختن آیتم های منو را آغاز کنید.',
    },
  ];

  const handleStepClick = (index) => {
    setSelectedStep(index);
  };

  return (
    <div className="steps-wrapper  py-0 py-lg-5" id="steps">
      <div className="container py-6">
        <h2 className="font-bold text-center section-title" >
          مراحل دریک نگاه
        </h2>
        <p className="text-center text-text  phone-font-18 font-20 mt-3">پروسه ی ثبت نام در داینز فقط <span className="text-main">یک دقیقه</span> زمان میبره</p>

        
        <div className="row align-items-center justify-content-center py-5">
            <div
              className={`col-md-4 mb-3 mb-lg-0`}
            >
              <div 
              className={`${selectedStep === 0 ? 'steps-box-selected' : ''} p-3 steps-box text-center  round`}
              onClick={() => handleStepClick(0)}

              >
                <span className="font-20 phone-font-18"><b>1 - </b>ثبت سریع سفارش و ثبت نام در داینز</span>
              </div>
            </div>
            <div
              className={`col-md-4 mb-3 mb-lg-0`}
            >
              <div 
              className={`${selectedStep === 1 ? 'steps-box-selected' : ''} p-3 steps-box  text-center  round`}
              onClick={() => handleStepClick(1)}

              >
                <span className="font-20 phone-font-18"><b>2 - </b>پرداخت آنلاین و تکمیل پروفایل</span>
              </div>
            </div>
            <div
              className={`col-md-4 mb-3 mb-lg-0`}
            >
              <div 
              className={`${selectedStep === 2 ? 'steps-box-selected' : ''} p-3 steps-box  text-center   round`}
              onClick={() => handleStepClick(2)}

              >
                <span className="font-20 phone-font-18"><b>3 - </b>فعال شدن منو و آغاز به ساختن آیتم ها</span>
              </div>
            </div>

          <div className="col-md-12  text-center steps-value-wrapper my-0 mt-lg-5 mx-auto">
            <img src={stepData[selectedStep].imageSrc} width={100} className="my-3" alt="step image" />
            <p className="step-desc mt-4 font-20 phone-font-18" style={{lineHeight: '2rem'}}>{stepData[selectedStep].text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Steps;
