import React from 'react';
import phonemookup from '../images/phonemookup.png';
import phoneHero from '../images/phone-hero.png';
import qrcode from '../images/qrcode.png';
import arrow from '../images/arrow.svg';
import demoVideo from '../images/demo.m4v'

const Hero = () => {
  return (
    <div className="hero-wrapper ">
    <div className="container">
    <div className="row align-items-start justify-content-between hero position-relative">
        <div className="text-center">
        <img className="phone-hero " src={phoneHero} alt="" />
        </div>
        <div className="col-lg-6 hero-right">
            <section className="">
                <h1 className="font-black mb-3 name">منوی دیجیتال داینز</h1>
                <h2 className="my-4 my-lg-5   font-bold sub-name  ">
                    تجربه‌ی متفاوت;
                    <br/>
                    ظاهر جداب و مدرن، شایسته ی برند شما
                </h2>
                <div className="my-lg-5 my-3  text-text" style={{fontSize: '18px'}}>
                    از ظاهر پیچیده و غیرجذاب منوهای دیجیتال خسته شدید؟
                    <p className="font-20">
                        <br /><b className="text-main">زیبایی و کیفیت</b> اولویت اول ماست.
                    </p>
                </div>
             <div className="mt-4 mb-5 phone-font-18 font-20">
             <a href="#howitworks" className="text-decoration-none d-lg-block d-none mb-2 mb-lg-4">داینز چه جوری کار میکنه؟ </a>
             <a href="#howitworks1" className="text-decoration-none d-block d-lg-none mb-2 mb-lg-4">داینز چه جوری کار میکنه؟ </a>
                <a href="#features" className="d-block text-decoration-none">چرا باید از منو دیجیتال داینز استفاده کنم؟</a>
             </div>
             <div className="mt-5 mb-5 d-none-1200 d-lg-flex  flex-nowrap align-items-center"style={{fontSize: '18px'}}>
                <div className=" ms-4 w-auto hero-emoji" >🍔</div>
                <div className=" ms-4 w-auto hero-emoji">🍕</div>
                <div className=" ms-4 w-auto  hero-emoji">🍟</div>
                <div className=" ms-4 w-auto hero-emoji" >🌭</div>
                <div className=" ms-4 w-auto hero-emoji" >🥪</div>
                <div className=" ms-4 w-auto  hero-emoji">🍩</div>
                <div className=" ms-4 w-auto hero-emoji">🍡</div>
             </div>
             </section>
             <div className="d-none-1200 qrcode-wrapper"  id="howitworks" style={{marginTop: '150px'}}>
                <div className="d-flex justify-content-between " >
                <img src={qrcode} className=" mb-4" width={170} alt="" />
                <h2>dinez.ir.<small className="text-main">برندشما</small></h2>
                <img src={arrow} className=" mb-4" style={{rotate: '30deg'}} width={120} alt="" />
                </div>
                <p className="text-text font-20">برای مشاهده نسخه آزمایشی تصویر بالا را اسکن کنید.</p>
                <p className="font-20"  style={{lineHeight: '2rem'}}>منوی دیجیتال داینز مستقیما توسط تیمی از برنامه نویسان به شما ارائه میشه و هر روز اشکالات برطرف و امکانات جدید به منو اضافه میشن.</p>
             </div>
        </div>
        <div  className="col-lg-6 hero-left text-center sticky-top justify-content-center flex-column flex-lg-row d-flex    ">
                <img src={phonemookup}  className="phone-mookup " alt="" />
                <video className="hero-video" controls="play" autoPlay loop>
                    <source src={demoVideo} type="video/mp4" />
                </video>
            <section id="howitworks1" className="my-5 d-lg-none  px-2">
            <div className="">
                <div className="d-flex align-items-center flex-column justify-content-center">
                    <img src={qrcode} className=" mb-4" width={250} alt="Qrcode" />
                    <h2 className="mb-4">dinez.ir.<small className="text-info">برندشما</small></h2>
                </div>
                <p className="text-text font-20">برای مشاهده نسخه آزمایشی تصویر بالا را اسکن کنید یا <a href="https://demo.dinez.ir" className="text-decoration-none text-main">اینجا ضربه بزنید</a>.</p>
                <p className="font-20">منوی دیجیتال داینز مستقیما توسط تیمی از برنامه نویسان به شما ارائه میشه و هر روز اشکالات برطرف و امکانات جدید به منو اضافه میشن.</p>
            </div>
            </section>
        </div>
        <div className="text-center position-absolute bottom-0 d-none d-lg-block  -4">
        <p className="font-20 text-white">
                    پروسه ی ثبت نام در داینز فقط <b className="text-main">یک دقیقه</b> زمان میبره :) <a href="#prices">امتحانش کن</a>
                </p>
        </div>
    </div>
    </div>
    </div>
  )
}

export default Hero