import React from 'react'

const PrivacyPolicy = ({showPrivacyPolicy, setShowPrivacyPolicy}) => {
    window.onclick = function(event) {
        if (event.target === document.querySelector('.pp-wrapper') ) {
            setShowPrivacyPolicy(false)
        }
    }
  return (
    <div className={`${showPrivacyPolicy ? '' : 'd-none'} pp-wrapper`}>
        <div className="pp text-text font-20 phone-font-18">
        <h3 className="text-main text-center font-bold mb-3">قوانین استفاده و حریم خصوصی</h3>
        <span className="text-white d-block">
        وظایف و تعهدات داینز از زمان تحویل منو به شما:
        </span>
        <ul>
        <li>
        پشتیبانی و برطرف کردن مشکلات مربوط به نرم افزار داینز به صورت رایگان
        </li>
        <li>
    آموزش و راهنمایی کار با منو و داشبورد مدیریت
        </li>
        <li>

    حفظ و نگهداری اطلاعات شخصی شما
        </li>
        <li>

    بر طرف کردن نقص های مربوط به منوی دیجیتال در سریعترین زمان
        </li>
        </ul>

        <ol>
        <li>
داینز تعهد میدهد که اطلاعات شخصی و اطلاعات مربوط به منو شما را امن نگه دارد و حق هیچگونه کپی برداری یا انتشار اطلاعات را نخواهد داشت.

</li>
<li>
داینز متعهد است که در پایان سرویس و یا طبق درخواست کاربر داینز بنا بر حذف حساب کاربری، تمام اطلاعات شخصی و منو را به صورت کامل حذف کند.

</li>

<li className="text-white">
لازم به ذکر است که داینز در سرور‌های داخلی میزبانی شده و انتظار میرود که در زمان‌های اختلالات اینترنت ایران بدون مشکل قابل دسترسی باشد اما از آنجایی که داینز هیچ دخالت و توانایی در برطرف کردن مشکلات این چنینی ندارد، هیچ مسئولیتی در قبال مشکلات مربوطه نخواهد داشت.

</li>

<li>
داینز با به روزترین متد های امنیتی توسعه داده شده است و بر روی سرورها و هاست شرکت های ارائه دهنده این امکانات میزبانی میشود در صورت هرگونه اختلال، هک، سرقت اطلاعات و یا نقص های مربوط به هاست و سرور، داینز برای رفع این مشکلات پیگیری های لازم را انجام خواهد داد اگرچه هیچ مسئولیتی مستقیما بر عهده داینز نخواهد بود.

</li>
<li>

از آنجایی که امکان بررسی و مراقبت تمام وقت از منو و محتوای ارائه شده آن وجود ندارد، داینز هیچ مسئولیتی در قبال انتشار محتوای فیک، نامشروع و نا مناسب نخواهد داشت.
</li>
        </ol>
        <span className="text-white d-block">
 وظایف و تعهدات شما به عنوان <b className="text-main">کاربر داینز</b> از زمان تحویل منو:
        </span>
    <ul>
        <li>
    انتشار محتوای واقعی و مشروع و مناسب با قوانین کشور
        </li>
        <li>
    مراقبت و حفظ اطلاعات شخصی مانند رمز عبور و دسترسی داشبورد مدیریت منو جهت پیشگیری از سرقت یا تغییر محتوای منو
        </li>
        <li>

    به روز رسانی، واقعی بودن قیمت ها و تصاویر مربوط به غذاها
        </li>
    </ul>

<p className="text-white"><b className="text-main">کاربر داینز موظف است</b> از هرگونه عملی که منجر به لطمه خوردن شهرت تجاری داینز شود حذر نماید:
</p>
<ol>
    <li>
کاربر داینز حق هیچگونه استفاده جانبی از داینز را ندارد.
    </li>
    <li>

کاربر داینز هیچ حقی از اختراع، کپی رایت، دیگر حقوق مالکیت داینز را ندارد.
    </li>
    <li>
کاربر داینز حق ندارد هیچ محصول، طرح و ایده مرتبط با داینز را به هر منظوری ساخته یا بفروشد.
    </li>
    <li>
کاربر عضو داینز این حق را به داینز میدهد که در صورت مغایرت محتوای منو با قوانین و شرایط، داینز تصویر و محتوا را حذف کند یا تغییر دهد.

    </li>
</ol>


        <h5 className="text-main font-bold text-center">
        با ثبت نام در داینز شما با تمام موارد ذکر شده اعلام موافقت میکنید.

        </h5>
        <a href="https://my.dinez.ir/register/" type="button" className="text-decoration-none font-20 font-bold  price-card-btn text-white mt-4">
            ثبت سفارش
        </a>
        </div>
    </div>
  )
}

export default PrivacyPolicy