import React from 'react'
import enamad from '../images/enamad.png'

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="container py-5 d-flex flex-wrap justify-content-around align-items-end">
        <div className="mb-3 mb-lg-0">
        <p className="text-center d-block phone-font-15">تمامی حقوق متعلق به داینز می‌باشد | سرویسی از پونزوب</p>
        <p className="text-lg-start text-center d-block phone-font-15" style={{fontFamily:'Arial', direction: 'ltr'}}>© 2023 <a className="text-white text-decoration-none" href="#">Dinez</a> From <a className="text-info text-decoration-none" rel="noreferrer" href="https://ponezweb.com">Ponezweb</a></p>
        </div>
        <div className="">
        <a referrerpolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=371802&amp;Code=zQVm5b8NtnmRvdGlwx5n"><img width={100} className="enamad" referrerpolicy="origin" src={enamad} alt="E-namad" style={{cursor: 'pointer'}} id="zQVm5b8NtnmRvdGlwx5n" /></a>
        <img width={100} className="d-inline" src="https://parspng.com/wp-content/uploads/2023/01/zarin-palpng.parspng.com_.png" alt="" />
        </div>
      </div>
    </div>
  )
}

export default Footer